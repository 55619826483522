import {findOne, on} from './helper';
import Swiper from 'swiper/swiper-bundle.min';
import {debounce} from 'throttle-debounce';

const solution = () => {
    const solution = findOne('.solution');
    const solutionSwiperContainer = findOne('.swiper-container', solution);
    const solutionBullet = findOne('.swiper-pagination', solution);

    const solutionSwiper = debounce ( 200 , () => {
        const swiperOptions = {
            speed: 500,
            pagination: {
                el: solutionBullet,
                type: 'bullets',
            }
        };

        if (solutionSwiper.swiper) {
            solutionSwiper.swiper.destroy(true, true);
        }

        if(window.innerWidth > 767) {
            return
        }

        solutionSwiper.swiper = new Swiper(solutionSwiperContainer, swiperOptions);
    });

    on(window, 'resize', solutionSwiper);

    solutionSwiper();
};

export default solution;