import {gsap, ScrollTrigger} from 'gsap/all';
import {findOne, on} from './helper';

gsap.registerPlugin(ScrollTrigger);

const DELAY = {
    // backdropFade:
};

const Mask = class {
    constructor() {
        this.visual = findOne('.visual');
        this.char = findOne('.char', this.visual);
        // this.backdrop = findOne('.backdrop');
        this.canvas = findOne('.mask', this.visual);
        this.ctx = this.canvas.getContext('2d');

        on(window, 'resize', this.reset.bind(this));
        this.reset();

        this.current = 0;
        this.end = ~~(50 * 1);
        this.draw();
    }

    reset() {
        this.dpr = window.devicePixelRatio || 1;
        this.stageWidth = this.visual.clientWidth;
        this.stageHeight = this.visual.clientHeight;
        this.canvas.width = this.stageWidth * this.dpr;
        this.canvas.height = this.stageHeight * this.dpr;
        this.startSize = this.char.clientWidth + ~~(this.char.clientWidth * (53 / 356)) * 2;
        this.ctx.scale(this.dpr, this.dpr);
    }

    render(callBack) {
        this.ctx.clearRect(0, 0, this.stageWidth, this.stageHeight);
        this.current++;
        this.draw();

        if (this.isEnd) {
            setTimeout(() => {
                callBack();
            }, 200);

            return;
        }

        requestAnimationFrame(this.render.bind(this, callBack),);
    }

    draw() {
        const ctx = this.ctx;
        let progress = this.current / this.end;
        progress = progress * progress * progress;
        const width = this.startSize + (this.stageWidth - this.startSize) * progress;
        const height = this.startSize + (this.stageHeight - this.startSize) * progress;
        const centerX = (this.stageWidth - width) / 2;
        const centerY = (this.stageHeight - height) / 2;

        ctx.save();
        ctx.beginPath();
        ctx.fillStyle = '#e2e2e2';
        ctx.fillRect(0, 0, this.stageWidth, this.stageHeight);
        ctx.globalCompositeOperation = 'destination-out';
        ctx.fillRect(centerX, centerY, width, height);
        ctx.restore();

        if (progress >= 1) this.isEnd = true;
    }
};

const Backdrop = class {
    constructor(backdrop) {
        this.backdrop = backdrop;
        this.backdropImage = findOne('span', backdrop);
    }

    fade(delay) {
        return new Promise((resolve) => {
            const img = new Image();
            img.onload = () => {
                this.backdropImage.style.backgroundImage = `url('${img.src}')`;
                this.backdrop.classList.add('fade');

                setTimeout(resolve, delay);
            };
            img.src = this.backdropImage.dataset.backdrop;
        })
    }

    in(delay) {
        this.backdrop.classList.add('in');

        return new Promise(resolve => setTimeout(resolve, delay));
    }
};

const Char = class {
    constructor(char) {
        this.char = char;
    }

    in(delay) {
        this.char.classList.add('in');

        return new Promise(resolve => setTimeout(resolve, delay));
    }

    out() {
        this.char.classList.add('out');
    }
};

const visual = () => {
    const visual = findOne('.visual');
    const elements = {};

    elements.visual = visual;
    elements.backdrop = findOne('.backdrop', visual);
    elements.char = findOne('.char', visual);
    elements.title = findOne('.title', visual);
    elements.titleStrong = findOne('strong', elements.title);

    const char = new Char(elements.char);
    const mask = new Mask();
    const backdrop = new Backdrop(elements.backdrop);

    if (location.hash) {
        mask.current = 50;
        mask.draw();
        backdrop.fade(0);
        backdrop.in(0);
        elements.title.classList.add('in');
        document.body.classList.remove('open-visual');

        return;
    }

    setTimeout(() => {
        window.scrollTo(0, 0);
    }, 10);

    [
        () => backdrop.fade(600),
        () => char.in(700),
        () => {
            mask.render(() => {
                char.out();
                elements.title.classList.add('in');

                setTimeout(() => {
                    document.body.classList.remove('open-visual');
                }, 1300);
            });
            return backdrop.in(800);
        }
    ].reduce((prevProm, item) => {
            return prevProm.then(() => item())
        }, Promise.resolve());
};

export default visual;