import {on} from './helper';

import visual from './visual';
import header from "./header";
import about from "./about";
import business from "./business";
import portfolio from "./portfolio";
import contactUs from "./contact-us";
import solution from "./solution";
import Aos from 'aos/dist/aos';

const app = () => {
    header();
    visual();
    about();
    business();
    portfolio();
    contactUs();
    solution();

    Aos.init({
        once: true,
        offset: -150,
        duration: 400,
        easing: 'ease-in-sine',
        anchorPlacement: 'bottom-bottom',
    });
};

on(document, 'DOMContentLoaded', app);
