import {find, findOne, on} from './helper';

const header = () => {
    const body = findOne('body');
    const header = findOne('.header');
    const gnbButton = findOne('button', header);
    const nav = findOne('nav', header);
    const gnbList = findOne('ul', nav);
    const gnbItem = find('a', nav);
    const sections = find('.main > section');

    const scrollSpy = () => {
        sections.forEach((section, index) => {

            if (section.getBoundingClientRect().top < 80) {
                gnbList.classList.remove('active-1', 'active-2', 'active-3', 'active-4', 'active-5', 'active-6');

                if (index > 0) {
                    header.classList.add('scroll');
                } else {
                    header.classList.remove('scroll');
                }

                if (index > 1) {
                    gnbList.classList.add(`active-${index}`);
                } else {
                    gnbList.classList.add(`active-1`);
                }
            }
        });
    };
    scrollSpy();

    const moveSection = (() => {
        const sections = gnbItem.reduce((sections, section) => {
            const id = section.getAttribute('href');

            sections[id] = findOne(id);

            return sections;
        }, {});

        return (id) => {
            window.scroll({
                top: (window.scrollY || window.pageYOffset) + sections[id].getBoundingClientRect().top,
                behavior: 'smooth'
            });
        }
    })();

    on(gnbButton, 'click', () => {
        if(!(header.classList.contains('on'))) {
            body.classList.add('open-modal');
            header.classList.add('on');

            return false;
        }

        body.classList.remove('open-modal');
        header.classList.remove('on');
    });

    on(window, 'resize', () => {
        if (window.innerWidth > 1199) {
            if (body.classList.contains('open-modal') || header.classList.contains('on')) {
                body.classList.remove('open-modal');
                header.classList.remove('on');
            }
        }
    });

    on(window, 'scroll', () => {
        scrollSpy();
    });

    gnbItem.forEach((item, index) => {
        on(item, 'click', (event) => {
            event.preventDefault();

            if (window.innerWidth < 1200) {
                body.classList.remove('open-modal');
                header.classList.remove('on');
            }

            moveSection(item.getAttribute('href'));
        });
    });
};

export default header;