import {findOne, on} from './helper';
import Swiper from 'swiper/swiper-bundle.min';
import {debounce} from 'throttle-debounce';

const business = () => {
    const business = findOne('.business');
    const category = findOne('.category', business);
    const categorySwiperContainer = findOne('.swiper-container', category);
    const categoryBullet = findOne('.swiper-pagination', category);

    const businessCategory = debounce ( 200 , () => {
        const swiperOptions = {
            speed: 500,
            pagination: {
                el: categoryBullet,
                type: 'bullets',
            }
        };

        if (businessCategory.swiper) {
            businessCategory.swiper.destroy(true, true);
        }

        if(window.innerWidth > 767) {
            return
        }

        businessCategory.swiper = new Swiper(categorySwiperContainer, swiperOptions);
    });

    on(window, 'resize', businessCategory);

    businessCategory();
};

export default business;