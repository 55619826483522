import {findOne, find, on} from './helper';
import axios from "axios";

const contactUs = () => {
    const body = findOne('body');
    const wrap = findOne('.contact-us');
    const contentButton = findOne('.info button', wrap);
    const elementForm = findOne('.form', wrap);
    const form = findOne('form', wrap);
    const inputs = find('input, textarea', form);
    const name = findOne('[name="storename"]', form);
    const url = findOne('[name="homeurl"]', form);
    const tel = findOne('[name="phone"]', form);
    const email = findOne('[name="email"]', form);
    const title = findOne('[name="title"]', form);
    const content = findOne('[name="content"]', form);
    const agree = findOne('[type="checkbox"]', form);
    const submit = findOne('[type="submit"]', form);
    const closeButton = findOne('.form .close', wrap);
    const loading = findOne('.loading', wrap);
    const openForm = (event) => {
        event.preventDefault();

        loading.classList.remove('on');
        elementForm.scrollTop = 0;
        body.classList.add('open-contact-us-form');
    };

    const closeForm = (event) => {
        event.preventDefault();

        body.classList.remove('open-contact-us-form');
    };

    const resetForm = () => {
        inputs.forEach(input => {
            switch (input.type) {
                case 'checkbox':
                    input.checked = false;
                    break;

                default:
                    input.value = '';
            }
        });
    };
    const validForm = () => {
        if (!name.value.trim()) {
            alert('상호명 / 이름을 입력해주세요');
            name.focus();
            return false;
        }

        // if (!url.value.trim()) {
        //     alert('홈페이지 URL을 입력해주세요');
        //     url.focus();
        //     return false;
        // }

        if (!tel.value.trim()) {
            alert('연락처를 입력해주세요');
            tel.focus();
            return false;
        }

        if (!/^[\d-]*$/g.test(tel.value.trim())) {
            alert('연락처를 확인해주세요');
            tel.focus();
            return false;
        }

        if (!email.value.trim()) {
            alert('이메일을 입력해주세요');
            email.focus();
            return false;
        }

        if (!/^([0-9a-zA-Z_\.-]+)@([0-9a-zA-Z_-]+)(\.[0-9a-zA-Z_-]+){1,2}$/.test(email.value.trim())) {
            alert('이메일을 확인해주세요');
            email.focus();
            return false;
        }

        if (!title.value.trim()) {
            alert('제목을 입력해주세요');
            title.focus();
            return false;
        }

        if (!content.value.trim()) {
            alert('문의내용을 입력해주세요');
            content.focus();
            return false;
        }

        if (!agree.checked) {
            alert('개인정보 수집 및 이용에 동의 해주세요');
            return false;
        }

        return true;
    };
    const submitForm = (event) => {
        event.preventDefault();

        if (validForm()) {
            const formData = new FormData(form);
            loading.classList.add('on');

            axios.post(form.action, formData)
                .then(response => {
                    loading.classList.remove('on');
                    alert(response.data.message);
                    closeForm(event);
                })
        }
    };

    on(contentButton, 'click', (event) => {
        resetForm();
        openForm(event);
    });
    on(closeButton, 'click', closeForm);
    on(submit, 'click', submitForm);
};

export default contactUs;