import {find, findOne, on} from './helper';
import Swiper from 'swiper/swiper-bundle.min';
import { debounce  } from 'throttle-debounce';

const about = () => {
    const about = findOne('.about');
    const swiperContainer = findOne('.swiper-container', about);
    const bullet = findOne('.swiper-pagination', about);
    const content = find('.swiper-slide', about);
    let current = 1;
    let listTimer;

    const listOpen = () => {
        content.forEach((value) => {
            value.classList.remove('on');
        });

        if (current > 4) {
            current = 1;
        }

        findOne(`.content-${current}`).classList.add('on');

        current++;
    };

    const initListOpen = () => {
        current = 1;

        listOpen();

        listTimer = setInterval(() => {
            listOpen()
        }, 5000);
    };

    const removeListOpen = () => {
        content.forEach((value) => {
            value.classList.remove('on');
        });

        clearTimeout(listTimer);

        current = 1;
    }

    const swiperOptions = {
        speed: 500,
        pagination: {
            el: bullet,
            type: 'bullets',
        }
    };

    const aboutUsList = debounce ( 200 , () => {
        if (aboutUsList.swiper) {
            aboutUsList.swiper.destroy(true, true);
        }

        if (window.innerWidth > 1199) {
            initListOpen();

            content.forEach((item) => {
                on(item, 'mouseenter', removeListOpen);

                on(item, 'mouseleave', initListOpen);
            });
        } else {
            removeListOpen();

            content.forEach((item) => {
                item.removeEventListener('mouseenter', removeListOpen);

                item.removeEventListener('mouseleave', initListOpen);
            });

        }

        if(window.innerWidth > 767) {
            return
        }

        aboutUsList.swiper = new Swiper(swiperContainer, swiperOptions);
    });

    on(window, 'resize', () => {
        aboutUsList();
    });

    aboutUsList();
};

export default about;