import {findOne, find, on} from './helper';
import Swiper, {Autoplay, Navigation, Pagination} from 'swiper/core';

Swiper.use([Autoplay, Navigation, Pagination]);

const portfolio = () => {
    // const detail = findOne('.portfolio .detail');
    // let detailWidth = detail.clientWidth;
    // const prevNext = findOne('.prev_next', detail);
    // const prev = findOne('.prev', prevNext);
    // const next = findOne('.next', prevNext);
    // const movePrevNext = (event) => {
    //     const isPrev = event.clientX < detailWidth / 2;
    //     prevNext.style.transform = `translate(${event.clientX}px, ${event.clientY}px)`;
    //
    //     prev.classList[isPrev ? 'add' : 'remove']('on');
    //     next.classList[isPrev ? 'remove' : 'add']('on');
    // };
    //

    const detailList = new Swiper('.portfolio .detail .swiper-container', {
        autoplay: {
            delay: 5000,
        },
        loop: true,
        slidesPerView: 'auto',
        centeredSlides: true,
        pagination: {
            el: '.portfolio .detail .swiper-pagination'
        },
        navigation: {
            prevEl: '.portfolio .detail .button-prev',
            nextEl: '.portfolio .detail .button-next',
        },
    });
    //
    // on(window, 'resize', () => {
    //     detailWidth = detail.clientWidth;
    // });
    //
    // on(detail, 'mouseenter', () => {
    //     prevNext.classList.add('on');
    // });
    //
    // on(detail, 'mouseleave', () => {
    //     prevNext.classList.remove('on');
    // });
    //
    // on(detail, 'mousemove', movePrevNext);
    //
    // on(prev, 'click', () => {
    //     console.log('click');
    //     detailList.slidePrev();
    // });
    //
    // on(next, 'click', () => {
    //     console.log('click');
    //     detailList.slideNext();
    // });
};

export default portfolio;