export const findOne = (selector, context= document) => context.querySelector(selector) || undefined;
export const find = (selector, context= document) => [].concat(...context.querySelectorAll(selector));
export const el = (tagName) => document.createElement(tagName);

export const on = (target, type, callback, capture) => target.addEventListener(type, callback, !!capture);
export const off = (target, type, callback) => target.removeEventListener(type, callback);
export const delegate = (target, selector, type, handler, capture) => {
    const dispatchEvent = (event) => {
        const targetElement = event.target;
        const potentialElements = targetElement.closest(selector);

        if (potentialElements) {
            handler.call(potentialElements, event);
        }
    };

    on(target, type, dispatchEvent, !!capture);
};

export const featureTest = (property, value) => {
    const prop = `${property}:`;
    const el = document.createElement('featureTest');
    const elStyle = el.style;

    elStyle.cssText = `${prop}${['-webkit-', '-moz-', '-ms-', '-o-', ''].join(`${value};${prop}`)}${value};`;

    return elStyle[property].includes(value);
};

export const nbFor = (max, load, block) => {
    let i = 0;
    const f = time => {
        let current = load;
        while (current-- && i < max) {
            block();
            i++;
        }
        if (i < max - 1) requestAnimationFrame(f);
    };
    requestAnimationFrame(f);
};